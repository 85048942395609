import React, {useEffect} from 'react';
import {ConditionsReportEditingSteps, ID_NEW, useInProgressProConditionsReport} from '../../../data/ConditionsReportProEditing';
import {IonButton, IonGrid, IonIcon, IonToolbar} from '@ionic/react';
import {PageWrapper} from '../../../components/PageWrapper';
import {ValidationWarnings} from '../../../components/ValidationWarning';
import {useHistory, useParams} from 'react-router';
import {conditionsReportRepository} from '../../../data/repository/ConditionsReport';
import {useI18n} from '../../../i18n/i18n';
import {useLoginState} from '../../../data/Login';
import {Redirect} from 'react-router-dom';

interface StepFormWrapperProps<TStep extends keyof ConditionsReportEditingSteps> {
    stepName: TStep,
    step: ConditionsReportEditingSteps[TStep],
}


export function StepFormWrapper<TStep extends keyof ConditionsReportEditingSteps>(props: StepFormWrapperProps<TStep>) {
    const {user} = useLoginState();
    const params = useParams() as { id: string };
    const history = useHistory();
    const {label} = useI18n();

    const {
        id,
        initialize,
        updateStepData,
        stepData,
        steps,
        validation,
        showValidation,
        goToStep,
        goToPreview,
    } = useInProgressProConditionsReport();

    useEffect(() => {
        if (params.id === id) {
            return;
        }

        if (params.id === ID_NEW) {
            initialize(null);
            return;
        }

        conditionsReportRepository.load(params.id).then(initialize);
    }, [params.id]);

    const stepNames = Object.keys(steps) as (keyof ConditionsReportEditingSteps)[];
    const currentIndex = stepNames.indexOf(props.stepName);
    const nextStep = stepNames[currentIndex + 1] ?? null;
    const previousStep = stepNames[currentIndex - 1] ?? null;

    const goBack = () => {
        if (previousStep) {
            goToStep(previousStep);
        } else {
            history.push('/');
        }
    };

    const iconColor = (step: keyof ConditionsReportEditingSteps) => {
        if (step === props.stepName) {
            return 'light';
        }

        if (showValidation && Object.keys(validation[step] ?? {}).length > 0) {
            return 'warning';
        }

        return 'medium';
    };

    if (user === null) {
        return <Redirect to="/home"/>;
    }

    return <PageWrapper
        title={label(props.step.titleLabel)}
        showBackButton={true}
        onBack={goBack}
        additionalHeaderContent={
            <IonToolbar color="primary">
                <IonGrid style={{display: 'flex', justifyContent: 'space-around'}}>
                    {stepNames.map(step => <IonButton fill="clear" style={{flexGrow: 1}} key={step}
                                                      onClick={() => goToStep(step)}>
                        <IonIcon color={iconColor(step)} icon={steps[step].icon} size="large"/>
                    </IonButton>)}
                </IonGrid>
            </IonToolbar>
        }
        footer={
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    {previousStep &&
                        <IonButton onClick={() => goToStep(previousStep)}>{label('editing.action.back')}</IonButton>}
                </div>
                {nextStep ? <IonButton onClick={() => goToStep(nextStep)}>{label('editing.action.next')}</IonButton> :
                    <IonButton onClick={() => goToPreview()}>{label('editing.action.send')}</IonButton>}
            </div>
        }
    >
        {showValidation && <ValidationWarnings errors={validation[props.stepName] ?? {}}/>}
        <props.step.render
            data={stepData[props.stepName] as any}
            step={props.step}
            allData={stepData}
            validation={(showValidation ? validation[props.stepName] ?? {} : {}) as any}
            showValidation={showValidation}
            updateData={(data: any) => updateStepData(props.stepName, data)}
        />
        <p className="ion-padding">{label('editing.required_fields')}</p>
    </PageWrapper>;
}