import {ConditionsReportWithId} from "./repository/ConditionsReport";
import {ConditionsReportMiniFormData, emptyFormData, validate} from "../pages/CreationForm/Mini/Form";
import React from "react";
import {useHistory} from "react-router";
import {ID_NEW} from "./ConditionsReportProEditing";
import {conditionsReportEditingStorage} from "./storage";
import {ConditionsReportMiniEditingPage} from "../pages/CreationForm/Mini/Page";
import {Route} from "react-router-dom";
import {useI18n} from "../i18n/i18n";
import {SubmitMini} from "../pages/CreationForm/Mini/SubmitMini";

export type ConditionsReportMiniValidationData = Partial<Record<keyof ConditionsReportMiniFormData, string[]>>

interface ConditionsReportMiniEditingContext {
  id: string,
  initialize: (cr: ConditionsReportWithId | null) => void,
  formData: ConditionsReportMiniFormData,
  updateFormData: (data: ConditionsReportMiniFormData) => Promise<void>,
  validation: ConditionsReportMiniValidationData,
  showValidation: boolean,
  deleteInProgressConditionsReport: () => Promise<void>,
  hasInProgressConditionsReport: boolean,
  goToPreview: () => void,
}

export const ConditionsReportMiniEditingContext = React.createContext<ConditionsReportMiniEditingContext | null>(null);
export const ConditionsReportMiniEditingProvider: React.FunctionComponent<{ children: React.ReactNode}> = ({ children }) => {
  const history = useHistory();
  const { label } = useI18n();

  const [ hasInProgressConditionsReport, setHasInProgressConditionsReport ] = React.useState(false);
  const [ formData, setFormData ] = React.useState<ConditionsReportMiniFormData>(emptyFormData());
  const [ validation, setValidation ] = React.useState<ConditionsReportMiniValidationData>({});
  const [ showValidation, setShowValidation ] = React.useState<boolean>(false);
  const [ id, setId ] = React.useState<string>(ID_NEW);

  // Load the current active form data from storage and merge with default data
  React.useEffect(() => {
    conditionsReportEditingStorage.loadMiniFormData(id).then(activeFormData => {
      setHasInProgressConditionsReport(Object.keys(activeFormData).length > 0);
      setFormData({ ...formData, ...activeFormData });
    });
  }, [ id ]);

  // Update validation
  React.useEffect(() => {
    setValidation(validate(formData, label));
  }, [ formData ]);

  async function initialize (cr: ConditionsReportWithId | null) {
    if (cr === null) {
      setId(ID_NEW);
    }

    throw new Error('Editing existing conditions reports is not yet implemented');
  }

  async function updateFormData (data: ConditionsReportMiniFormData): Promise<void> {
    const newFormData = { ...data };
    setFormData(newFormData);
    await conditionsReportEditingStorage.storeMiniFormData(id, data);
    setHasInProgressConditionsReport(true);
  }

  async function deleteInProgressConditionsReport (): Promise<void> {
    await conditionsReportEditingStorage.removeMiniFormData(id);
    setFormData(emptyFormData());
    setHasInProgressConditionsReport(false);
  }

  async function goToPreview(): Promise<void> {
    setShowValidation(true);
    if (Object.keys(validation).length > 0) {
      return;
    }

    history.push(`/edit/mini/${id}/submit`);
  }

  return <ConditionsReportMiniEditingContext.Provider value={{
    id,
    initialize,
    formData,
    updateFormData,
    validation,
    showValidation,
    deleteInProgressConditionsReport,
    hasInProgressConditionsReport,
    goToPreview
  }}>{children}</ConditionsReportMiniEditingContext.Provider>
}

export function useInProgressMiniConditionsReport(): ConditionsReportMiniEditingContext {
  return React.useContext(ConditionsReportMiniEditingContext)!;
}

export function getConditionsReportMiniRoutes(): React.ReactElement[] {
  return [
    <Route exact path={`/edit/mini/:id`} key="mini"><ConditionsReportMiniEditingPage /></Route>,
    <Route exact path={`/edit/mini/:id/submit`} key="mini-submit"><SubmitMini /></Route>,
  ];
}