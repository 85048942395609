import {GeolocationInformation, GeolocationProvider} from "./index";
import {getDistance} from "geolib";

export class WebGeolocationProvider implements GeolocationProvider {
    getGeolocation(): Promise<GeolocationInformation> {
        return new Promise((resolve, reject) => {
            window.navigator.geolocation.getCurrentPosition(
              location => resolve({ latitude: location.coords.latitude, longitude: location.coords.longitude }),
              error => reject(error),
              { enableHighAccuracy: true },
            )
        })
    }


    distanceBetween(a: GeolocationInformation, b: GeolocationInformation): number {
        return getDistance(a, b);
    }
}