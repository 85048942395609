import {PickedFile} from '@capawesome/capacitor-file-picker';
import {Capacitor} from '@capacitor/core';
import {GenericVideoHandlingBackend} from './GenericVideoHandlingBackend';
import {FileHandlingBackend, TemporaryFileHandler} from '../files';
import {createVideoThumbnailUsingCapacitorPlugin} from "./helpers";

export class AndroidVideoHandlingBackend extends GenericVideoHandlingBackend {
    constructor(
        fileHandling: FileHandlingBackend,
        private readonly temporaryFiles: TemporaryFileHandler,
    ) {
        super(fileHandling);
    }

    async canLoadVideosFromCamera(): Promise<boolean> {
        return true;
    }

    async canLoadVideosFromGallery(): Promise<boolean> {
        return true;
    }

    protected getVideoLength(file: PickedFile): Promise<number> {
        return Promise.resolve(file.duration!);
    }

    // Android has issues with the resulting URLs, therefor the data is read
    // and copied to memory instead of handled as path. This is not ideal memory
    // wise but the only way to get it working on Android.
    // See: https://github.com/capawesome-team/capacitor-plugins/issues/158
    protected async extractPathsFromGalleryFile(file: PickedFile): Promise<{ pathOnDisk: string, publicPath: string }> {
        const uri = Capacitor.convertFileSrc(file.path!);
        const result = await fetch(uri);
        const fileContents = await result.blob();

        const pathOnDisk = await this.temporaryFiles.writeToDisk(file.name, fileContents);
        const publicPath = Capacitor.convertFileSrc(pathOnDisk);

        return {pathOnDisk, publicPath};
    }

    protected async createVideoThumbnail(path: string): Promise<string> {
        return createVideoThumbnailUsingCapacitorPlugin(path);
    }
}