import {IonIcon} from '@ionic/react';
import {COLORS} from '../environment';
import React from 'react';
import {star as starIcon} from 'ionicons/icons';

interface StarSelectorProps {
    value: number,
    onChange?: (value: number) => void,
    max: number,
}

export const StarSelector: React.FunctionComponent<StarSelectorProps> = (props) => {
    const stars = Array.from({length: props.max}, (_, i) => i + 1);

    const onClick = (star: number) => {
        if (props.onChange) {
            props.onChange(star);
        }
    };

    return <div>
        {stars.map(star => <IonIcon
            key={star}
            icon={starIcon}
            style={{color: star <= props.value ? COLORS.LightBlue : COLORS.GREY, fontSize: '2rem'}}
            onClick={() => onClick(star)}
        />)}
    </div>;
};