import React from 'react';
import {useHistory} from "react-router";
import {SubmitGeneric} from "../SubmitGeneric";
import {
  ConditionsReportMiniValidationData,
  useInProgressMiniConditionsReport
} from "../../../data/ConditionsReportMiniEditing";
import {ConditionsReportWithTemporaryMedia, convertFormDataToMiniCr} from "../Pro/conversion";
import {MiniConditionsReport} from "../../../data/ConditionsReport";
import {Alert} from "@mui/material";
import {useI18n} from "../../../i18n/i18n";
import {IonList} from "@ionic/react";

export const SubmitMini: React.FunctionComponent = () => {
  const history = useHistory();
  const { locale } = useI18n();
  const { validation, formData, deleteInProgressConditionsReport } = useInProgressMiniConditionsReport();

  const [ cr, setCr ] = React.useState<ConditionsReportWithTemporaryMedia<MiniConditionsReport>|null>(null);

  React.useEffect(() => {
    setCr(convertFormDataToMiniCr(formData, locale));
  }, [ formData ]);

  return <SubmitGeneric
    cr={cr}
    goBack={() => history.goBack()}
    deleteInProgressConditionsReport={deleteInProgressConditionsReport}
  >
    <ValidationDetails validation={validation} />
  </SubmitGeneric>
}

interface ValidationDetailsProps {
  validation: ConditionsReportMiniValidationData,
}

const ValidationDetails: React.FunctionComponent<ValidationDetailsProps> = (props) => {
  const {label} = useI18n();

  if (Object.keys(props.validation).length === 0) {
    return null;
  }

  const errors: React.ReactNode[] = [ ];
  for (const [key, messages] of Object.entries(props.validation)) {
    for (const [ i, message ] of Object.entries(messages)) {
      errors.push(<IonList key={`${key}_${i}`}>{message}</IonList>);
    }
  }

  return <Alert severity="warning">
    <h2>{label('submit.error.validation.title')}</h2>
    <IonList>{errors}</IonList>
  </Alert>
}