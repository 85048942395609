export function base64Encode(data: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onerror = reject;
        fileReader.onload = () => {
            resolve(String(fileReader.result));
        };
        fileReader.readAsDataURL(data);
    });
}

export function base64Decode(encoded: string, mimeType: string): Blob {
    const byteCharacters = atob(encoded);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], {type: mimeType});
}
