import './instrumentation';
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {environment} from './environment';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {StatusBar, Style} from '@capacitor/status-bar';

defineCustomElements(window);

// eslint-disable-next-line no-console
console.log(JSON.stringify(environment, null, 4));

if (environment.baseUrl && environment.baseUrl !== '/') {
    document.querySelector('base')?.setAttribute('href', environment.baseUrl);
}

const container = document.getElementById('ionic-content-root');
if (container && environment.isEmbedded) {
    container.classList.add('embedded');
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (!environment.isProduction) {
    // eslint-disable-next-line no-console
    reportWebVitals(console.log);
}

// eslint-disable-next-line no-console
StatusBar.setStyle({style: Style.Dark}).catch((e) => console.warn(e));
// eslint-disable-next-line no-console
StatusBar.setBackgroundColor({color: environment.isProduction ? '#00234c' : '#cf3c4f'}).catch((e) => console.warn(e));
