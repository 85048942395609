import React, {useEffect, useState} from 'react';
import {useStorage} from './Helpers';
import {AuthStatus, User, userRepository} from './repository/User';
import {Device} from '@capacitor/device';
import {cacheStorage} from './repository/CacheStorage';
import {useHistory} from 'react-router';
import {createNewTokenStorage} from './token-storage';

interface UserData extends User {
    token: string,
}

interface LoginStateContext {
    user: UserData | null,
    login: (email: string, password: string) => Promise<UserData | null>,
    logout: () => Promise<void>,
    isFixed: boolean,
}

const LoginStateContext = React.createContext<LoginStateContext | null>(null);
export const LoginStateContextProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({children}) => {
    const history = useHistory();
    const [storage] = useStorage();
    const tokenStorage = createNewTokenStorage(storage);
    const [user, setUser] = useState<UserData | null>(null);
    const [isFixed, setIsFixed] = React.useState<boolean>(false);

    async function initialize(token: string|null) {
        if (!token || token === user?.token) {
            return;
        }

        const fromCache = cacheStorage.get<UserData>(token);
        if (fromCache) {
            setUser(fromCache);
        }

        try {
            const [status, user] = await userRepository.getUserData(token);
            if (status === AuthStatus.AUTHENTICATED && user) {
                setUser({...user, token});
                cacheStorage.set(token, {...user, token});
            }
            if (status === AuthStatus.UNAUTHENTICATED) {
                console.error(`Previously stored token ${token} is invalid. Logging out.`);
                setUser(null);
                await tokenStorage.remove();
                history.push('/');
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    React.useEffect(() => {
        tokenStorage.allowsModification().then(allowsModification => setIsFixed(!allowsModification));
    }, []);

    useEffect(() => {
        const tokenFromUrl = new URLSearchParams(window.location.search).get('_t');
        if (tokenFromUrl) {
            console.log('Setting token from URL');
            tokenStorage.set(tokenFromUrl)
              .then(() => window.history.replaceState(window.history.state, '', window.location.href.split('?')[0]))
              .then(() => initialize(tokenFromUrl));
        } else {
            tokenStorage.get()
              .then(token => initialize(token));
        }
    }, []);


    async function login(email: string, password: string): Promise<UserData | null> {
        const deviceId = await Device.getId();
        const token = await userRepository.login(email, password, deviceId.identifier);
        if (token === null) {
            return null;
        }
        const [state, user] = await userRepository.getUserData(token);
        if (state === AuthStatus.AUTHENTICATED && user) {
            await tokenStorage.set(token);

            const userData = {...user, token};
            setUser(userData);
            cacheStorage.set(token, userData);

            return userData;
        }

        return null;
    }

    async function logout(): Promise<void> {
        setUser(null);
        await tokenStorage.remove();
    }

    return <LoginStateContext.Provider value={{user, login, logout, isFixed}}>{children}</LoginStateContext.Provider>;
};

export function useLoginState() {
    return React.useContext(LoginStateContext)!;
}
