import {FileOnDisk} from './index';
import {GenericFileHandlingBackend} from './GenericFileHandlingBackend';
import {Filesystem} from '@capacitor/filesystem';
import {base64Decode} from './helpers';

export class WebFileHandlingBackend extends GenericFileHandlingBackend {

    async readFile(file: FileOnDisk): Promise<Blob> {
        const result = await Filesystem.readFile({
            path: file.nativePath,
        });

        if (result.data instanceof Blob) {
            return result.data;
        }

        return base64Decode(result.data as string, file.mimeType);
    }

    async getPublicUrl(file: FileOnDisk): Promise<string> {
        const blob = await this.readFile(file);
        return URL.createObjectURL(blob);
    }
}
