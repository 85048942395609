import {PickedFile} from '@capawesome/capacitor-file-picker';
import {GenericVideoHandlingBackend} from './GenericVideoHandlingBackend';
import {FileHandlingBackend, TemporaryFileHandler} from '../files';
import {createVideoThumbnailUsingHtmlVideoSnapshot} from "./helpers";

export class WebVideoHandlingBackend extends GenericVideoHandlingBackend {
    constructor(
        fileHandling: FileHandlingBackend,
        private readonly temporaryFiles: TemporaryFileHandler,
    ) {
        super(fileHandling);
    }

    async canLoadVideosFromCamera(): Promise<boolean> {
        return false;
    }

    async canLoadVideosFromGallery(): Promise<boolean> {
        return true;
    }

    protected async getVideoLength(file: PickedFile): Promise<number> {
        const publicPath = URL.createObjectURL(file.blob!);
        const video = await this.createVideoElement(publicPath)

        return video.duration;
    }

    // Web: File is not written to disk, but returned as a blob.
    //      We need to write the blob to disk ourselves.
    protected async extractPathsFromGalleryFile(file: PickedFile): Promise<{ pathOnDisk: string, publicPath: string }> {
        const pathOnDisk = await this.temporaryFiles.writeToDisk(file.name, file.blob!);
        const publicPath = URL.createObjectURL(file.blob!);

        return {pathOnDisk, publicPath};
    }

    protected async createVideoThumbnail(path: string, videoElement: HTMLVideoElement): Promise<string> {
        return createVideoThumbnailUsingHtmlVideoSnapshot(path, videoElement, this.temporaryFiles);
    }
}