import {TokenStorage} from './index';

export class WebTokenStorage implements TokenStorage {
    constructor(
        private readonly storageKey: string,
    ) {
    }

    async allowsModification(): Promise<boolean> {
        return true;
    }

    async get(): Promise<string | null> {
        return window.localStorage.getItem(this.storageKey);
    }

    async set(token: string): Promise<void> {
        window.localStorage.setItem(this.storageKey, token);
    }

    async remove(): Promise<void> {
        window.localStorage.removeItem(this.storageKey);
    }

}