import {NewsItem} from '../News';
import {environment} from '../../environment';

interface ApiResponse {
    [id: string]: Omit<NewsItem, 'id'>;
}

class NewsRepository {
    async get(): Promise<NewsItem[]> {
        const response = await fetch(`${environment.powderguideWebsiteUrl}/app-news.json`);
        const body = await response.json() as ApiResponse;

        const items: NewsItem[] = [];
        for (const [key, value] of Object.entries(body)) {
            items.push({...value, id: key});
        }

        return items;
    }
}

export const newsRepository = new NewsRepository();