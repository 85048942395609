import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import {environment} from './environment';

let dist = 'production';
if (!environment.isProduction) {
    dist = 'testing';
}
if (environment.powderguideWebsiteUrl.indexOf('.ddev.site') > -1) {
    dist = 'dev';
}


Sentry.init({
    dsn: environment.sentryDsn,
    version: environment.version,
    dist,

    tracePropagationTargets: [
        'localhost',
        environment.powderguideWebsiteUrl,
    ],
}, SentryReact.init);
