import {Directory, Filesystem, StatResult} from '@capacitor/filesystem';
import {FileHandlingBackend, FileOnDisk} from './index';

export abstract class GenericFileHandlingBackend implements FileHandlingBackend {

    abstract getPublicUrl(file: FileOnDisk): Promise<string>;

    abstract readFile(file: FileOnDisk): Promise<Blob>;

    async listFiles(): Promise<FileOnDisk[]> {
        const directories = [''];
        const files: FileOnDisk[] = [];

        while (directories.length > 0) {
            const currentDir = directories.pop()!;
            const [currentFiles, subDirectories] = await this.readDir(currentDir);
            files.push(...currentFiles);
            directories.push(...subDirectories.map(subDir => `${currentDir}/${subDir}`));
        }

        return files;
    }

    private async readDir(dir: string): Promise<[FileOnDisk[], string[]]> {
        const result = await Filesystem.readdir({
            path: dir,
            directory: Directory.Data,
        });

        const subDirectories: string[] = [];
        const files: FileOnDisk[] = [];
        for (const file of result.files) {
            if (file.type === 'file') {
                files.push(await this.getFile(file.uri));
            } else {
                subDirectories.push(file.name);
            }
        }

        return [files, subDirectories];
    }

    async getFile(path: string): Promise<FileOnDisk> {
        await Filesystem.requestPermissions();

        let stat: StatResult;
        try {
            stat = await Filesystem.stat({path});
        } catch (e: any) {
            throw new Error(`File not found: ${path} (${e.message})`);
        }

        const pathParts = path.split('/');
        const fileName = pathParts[pathParts.length - 1];

        return {
            fileName,
            size: stat.size,
            sizeMb: Math.round(stat.size / 1024 / 1024 * 100) / 100,
            nativePath: stat.uri,
            mimeType: stat.type,
        };
    }

    async deleteFile(file: FileOnDisk): Promise<void> {
        await Filesystem.deleteFile({
            path: file.nativePath,
        });
    }
}
