import {Storage} from '@ionic/storage';
import {StepData} from './ConditionsReportProEditing';
import {ConditionsReportMiniFormData} from "../pages/CreationForm/Mini/Form";

class ConditionsReportEditingStorage {

    private storage: Storage;

    constructor() {
        this.storage = new Storage();
        this.storage.create();
    }

    private miniStorageKey(id: string): string {
        return `conditions_report_mini_edit.${id}`;
    }

    async loadMiniFormData(id: string): Promise<Partial<ConditionsReportMiniFormData>> {
        const key = this.miniStorageKey(id);
        const result = await this.storage.get(key) as Partial<ConditionsReportMiniFormData> | null;

        return result ?? {};
    }

    async storeMiniFormData(id: string, data: ConditionsReportMiniFormData): Promise<void> {
        const key = this.miniStorageKey(id);
        await this.storage.set(key, data);
    }

    async removeMiniFormData(id: string): Promise<void> {
        const key = this.miniStorageKey(id);
        await this.storage.remove(key);
    }

    async hasMiniFormData(id: string): Promise<boolean> {
        const key = this.miniStorageKey(id);
        const result = await this.storage.get(key) as Partial<ConditionsReportMiniFormData> | null;

        return result !== null;
    }

    private proStorageKey(id: string): string {
        return `conditions_report_edit.${id}`;
    }

    async loadProStepData(id: string): Promise<Partial<StepData>> {
        const key = this.proStorageKey(id);
        const result = await this.storage.get(key) as Partial<StepData> | null;

        return result ?? {};
    }

    async storeProStepData(id: string, data: StepData): Promise<void> {
        const key = this.proStorageKey(id);
        await this.storage.set(key, data);
    }

    async removeProStepData(id: string): Promise<void> {
        const key = this.proStorageKey(id);
        await this.storage.remove(key);
    }

    async hasProStepData(id: string): Promise<boolean> {
        const key = this.proStorageKey(id);
        const result = await this.storage.get(key) as Partial<StepData> | null;

        return result !== null;
    }
}

export const conditionsReportEditingStorage = new ConditionsReportEditingStorage();