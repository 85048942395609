import {
    ConditionsReport,
    ConditionsReportLanguage,
    MiniConditionsReport,
    ProConditionsReport,
    ProConditionsReportWithLegacyInformation,
} from '../../../data/ConditionsReport';
import {TemporaryVideo} from '../../../data/videos';
import {StepData} from '../../../data/ConditionsReportProEditing';
import {TemporaryImage} from '../../../data/images';
import {ConditionsReportMiniFormData} from "../Mini/Form";
import {Language} from "../../../i18n/i18n";

export interface ConditionsReportWithTemporaryMedia<TReport = ConditionsReport> {
    conditionsReport: TReport,
    temporaryImages: TemporaryImage[],
    temporaryVideos: TemporaryVideo[],
}

function mapLanguage(language: Language): ConditionsReportLanguage {
    switch (language) {
        case 'de':
            return ConditionsReportLanguage.German;
        case 'en':
            return ConditionsReportLanguage.English;
        default:
            throw new Error(`Unsupported language: ${language}`);
    }
}

export function convertFormDataToMiniCr(data: ConditionsReportMiniFormData, language: Language): ConditionsReportWithTemporaryMedia<MiniConditionsReport> {

    return {
        conditionsReport: {
            type: 'mini',
            language: mapLanguage(language),
            title: data.title,
            activity_date: data.activityDate,
            rating: data.rating,
            description: data.description,
            media: [ ],
            videos: [ ],
        },
        temporaryImages: [ ],
        temporaryVideos: [ ],
    };
}

/**
 * Converts the step data to a conditions report.
 */
export function convertStepDataToProCr(data: StepData, language: Language): ConditionsReportWithTemporaryMedia<ProConditionsReport> {
    const cr: ConditionsReport = {
        type: 'pro',
        language: mapLanguage(language),
        title: data.step4.title,
        activity_date: data.step1.date,
        activity: data.step1.kind,
        rating: data.step4.overallRating * 2,
        description: data.step4.overallDescription,
        trend: data.step4.overallTrend!,
        media: [],
        videos: [],
        exposition: data.step1.exposition,
        avalanche_warning_level: data.step3.avalancheWarningLevel,
        altitude_lower: data.step1.altitude.lower,
        altitude_upper: data.step1.altitude.upper,
        spot_id: data.step1.location.uuid,
        snow_quality: {
            rating: data.step2.snowQualityRating! * 2,
            description: data.step2.snowQualityDescription,
            old_snow: data.step2.snowQualityOldSnow,
            breakable_crust: data.step2.snowQualityBreakableCrust,
            slush: data.step2.snowQualitySlush,
            firn: data.step2.snowQualityFirn,
            wet_snow: data.step2.snowQualityWetSnow,
            powder: data.step2.snowQualityPowder,
            summer_snow: data.step2.snowQualitySummerSnow,
            jelly_snow: data.step2.snowQualityJellySnow,
            wind_drifted: data.step2.snowQualityWindDrifted,
            wind_drifted_exposition: data.step2.snowQualityWindDriftedExposition,
            wind_drifted_altitude_upper: data.step2.snowQualityWindDriftedAltitude?.upper,
            wind_drifted_altitude_lower: data.step2.snowQualityWindDriftedAltitude?.lower,
            wind_drifted_intensity: data.step2.snowQualityWindDriftedIntensity,
            wind_drifted_description: data.step2.snowQualityWindDriftedDescription,
        },
        snow_condition: {
            rating: data.step2.snowConditionRating! * 2,
            description: data.step2.snowConditionDescription,
            descent_to_valley: data.step2.snowConditionDescentToValley,
            descent_to_height: data.step2.snowConditionDescentToHeight,
            descent_through_forest: data.step2.snowConditionDescentThroughForest,
            blown_backs: data.step2.snowConditionBlownBacks,
            covered_shoes: data.step2.snowConditionCoveredShoes,
            variations_of_snow_cover: data.step2.snowConditionVariationsOfSnowCover,
            stone_contact_avoidable: data.step2.snowConditionStoneContactAvoidable,
            fresh_snow: data.step2.snowConditionFreshSnow!,
        },
        risk: {
            rating: 0,
            description: data.step3.riskDescription,
            no_warning_signs: data.step3.riskNoWarningSigns,
            abort: data.step3.riskAbort,
            cracking: data.step3.riskCracking,
            fresh_avalanches: data.step3.riskFreshAvalanches,
            rockfall: data.step3.riskRockfall,
            crevices: data.step3.riskCrevices,
            diurnal_warming: data.step3.riskDiurnalWarming,
            much_fresh_snow: data.step3.riskMuchFreshSnow,
            much_water: data.step3.riskMuchWater,
            hissing_noise: data.step3.riskHissingNoise,
            old_snow_problem: data.step3.riskOldSnowProblem,
            wind_direction: data.step3.riskWindDirection,
            wind_intensity: data.step3.riskWindIntensity!,
            avalanche_exposition: data.step3.riskFreshAvalancheExposition,
            avalanche_altitude: data.step3.riskFreshAvalancheAltitude,
            avalanche_size: data.step3.riskFreshAvalancheSize,
            avalanche_type: data.step3.riskFreshAvalancheType,
            avalanche_description: data.step3.riskFreshAvalancheDescription,
        },
    };

    return {
        conditionsReport: cr,
        temporaryImages: data.step4.images,
        temporaryVideos: data.step4.videos,
    };
}

export function addLegacyInformationToCr(cr: ProConditionsReport): ProConditionsReportWithLegacyInformation {
    const data: ProConditionsReportWithLegacyInformation = {
        ...cr,
        video: cr.videos.length > 0 ? cr.videos.map(v => v.external_id).join(',') : null,
        snowquality: {
            rating: cr.snow_quality.rating,
            description: cr.snow_quality.description,
            attributes: {
                old_snow: cr.snow_quality.old_snow,
                breakable_crust: cr.snow_quality.breakable_crust,
                slush: cr.snow_quality.slush,
                firn: cr.snow_quality.firn,
                wet_snow: cr.snow_quality.wet_snow,
                powder: cr.snow_quality.powder,
                summer_snow: cr.snow_quality.summer_snow,
                jelly_snow: cr.snow_quality.jelly_snow,
                wind_drifted: cr.snow_quality.wind_drifted,
            },
        },
        snowcondition: {
            rating: cr.snow_condition.rating,
            description: cr.snow_condition.description,
            attributes: {
                descent_to_valley: cr.snow_condition.descent_to_valley,
                descent_to_height: cr.snow_condition.descent_to_height,
                descent_through_forest: cr.snow_condition.descent_through_forest,
                blown_backs: cr.snow_condition.blown_backs,
                covered_shoes: cr.snow_condition.covered_shoes,
                variations_of_snow_cover: cr.snow_condition.variations_of_snow_cover,
                stone_contact_avoidable: cr.snow_condition.stone_contact_avoidable,
                fresh_snow: cr.snow_condition.fresh_snow!,
            },
        },
        risk: {
            ...cr.risk,
            attributes: {
                no_warning_signs: cr.risk.no_warning_signs,
                abort: cr.risk.abort,
                cracking: cr.risk.cracking,
                fresh_avalanches: cr.risk.fresh_avalanches,
                rockfall: cr.risk.rockfall,
                crevices: cr.risk.crevices,
                diurnal_warming: cr.risk.diurnal_warming,
                much_fresh_snow: cr.risk.much_fresh_snow,
                much_water: cr.risk.much_water,
                hissing_noise: cr.risk.hissing_noise,
                old_snow_problem: cr.risk.old_snow_problem,
                wind_direction: cr.risk.wind_direction,
                wind_intensity: cr.risk.wind_intensity!,
            },
        },
        overall: {
            rating: cr.rating,
            description: cr.description,
            attributes: {
                trend: cr.trend,
                index_of_first_image: 0,
            },
        },
    };

    if (cr.snow_quality.wind_drifted) {
        data.snowquality.attributes.wind_drifted_attrs = {
            exposition: cr.snow_quality.wind_drifted_exposition ?? [ ],
            altitude_upper: cr.snow_quality.wind_drifted_altitude_upper ?? 0,
            altitude_lower: cr.snow_quality.wind_drifted_altitude_lower ?? 0,
            intensity: cr.snow_quality.wind_drifted_intensity ?? 0,
            description: cr.snow_quality.wind_drifted_description ?? '',
        }
    }

    if (cr.risk.fresh_avalanches) {
        data.risk.attributes.fresh_avalanches_attrs = {
            exposition: cr.risk.avalanche_exposition!,
            altitude: cr.risk.avalanche_altitude!,
            size: cr.risk.avalanche_size!,
            type: cr.risk.avalanche_type!,
            description: cr.risk.avalanche_description!,
        };
    }

    return data;
}
