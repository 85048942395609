import React from 'react';
import {ConditionsReportMiniValidationData} from "../../../data/ConditionsReportMiniEditing";
import {LabelFunc} from "../../../i18n/i18n";

export interface ConditionsReportMiniFormData {
  title: string,
  activityDate: string,
  rating: number,
  description: string,
}

export function emptyFormData(): ConditionsReportMiniFormData {
  return {
    title: '',
    activityDate: '',
    rating: 0,
    description: '',
  }
}

export function validate(formData: ConditionsReportMiniFormData, label: LabelFunc): ConditionsReportMiniValidationData {
  const validation: ConditionsReportMiniValidationData = {};

  if (formData.title.length === 0) {
    validation.title = [ label('cr.title.validation.exists') ];
  }

  // TODO

  return validation;
}

interface ConditionsReportMiniFormProps {
  formData: ConditionsReportMiniFormData,
  updateFormData: (data: ConditionsReportMiniFormData) => Promise<void>,
  validation: ConditionsReportMiniValidationData,
}

export const ConditionsReportMiniForm: React.FunctionComponent<ConditionsReportMiniFormProps> = () => {
  // TODO

  return <p>Test</p>
}