import {Directory, Filesystem} from '@capacitor/filesystem';
import {v4 as uuidv4} from 'uuid';
import {TemporaryFileHandler} from './index';
import write_blob from 'capacitor-blob-writer';

export class GenericTemporaryFileHandler implements TemporaryFileHandler {
    public constructor(
        private readonly baseDirectory: string,
    ) {

    }

    async writeToDisk(fileName: string, data: Blob): Promise<string> {
        await Filesystem.requestPermissions();

        const uuid = uuidv4();
        await Filesystem.mkdir({
            path: `${this.baseDirectory}/${uuid}`,
            recursive: true,
            directory: Directory.Data,
        });

        await write_blob({
            path: `${this.baseDirectory}/${uuid}/${fileName}`,
            directory: Directory.Data,
            blob: data,
            fast_mode: true,
        });

        const stat = await Filesystem.stat({
            path: `${this.baseDirectory}/${uuid}/${fileName}`,
            directory: Directory.Data,
        });

        return stat.uri;
    }

    async clearStorage(): Promise<void> {
        await Filesystem.rmdir({
            path: this.baseDirectory,
            directory: Directory.Data,
            recursive: true,
        });
    }
}
