import {PickedFile} from '@capawesome/capacitor-file-picker';
import {Capacitor} from '@capacitor/core';
import {GenericVideoHandlingBackend} from './GenericVideoHandlingBackend';
import {createVideoThumbnailUsingCapacitorPlugin} from "./helpers";

export class IosVideoHandlingBackend extends GenericVideoHandlingBackend {
    async canLoadVideosFromCamera(): Promise<boolean> {
        return true;
    }

    async canLoadVideosFromGallery(): Promise<boolean> {
        return true;
    }

    protected getVideoLength(file: PickedFile): Promise<number> {
        return Promise.resolve(file.duration!);
    }

    // Ios: The file path returned by pickVideos can be accessed directly.
    protected async extractPathsFromGalleryFile(file: PickedFile): Promise<{ pathOnDisk: string, publicPath: string }> {
        return {
            pathOnDisk: file.path!,
            publicPath: Capacitor.convertFileSrc(file.path!),
        };
    }

    protected async createVideoThumbnail(path: string): Promise<string> {
        return createVideoThumbnailUsingCapacitorPlugin(path);
    }
}