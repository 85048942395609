import {IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonProgressBar, IonText, IonThumbnail} from '@ionic/react';
import React from 'react';
import {play, videocam} from 'ionicons/icons';
import {Alert} from '@mui/material';
import {TemporaryVideo, ValidationError, ValidationFunction, videoHandling} from '../data/videos';
import {TemporaryVideoPreview} from './TemporaryFilepreview';
import {ErrorBox} from './ErrorBox';
import {useI18n} from '../i18n/i18n';
import {fileHandling} from '../data/files';

interface VideoPickerProps {
    max: number,
    maxSizeMb: number,
    maxLengthS: number,
    videos: TemporaryVideo[],
    onChange: (videos: TemporaryVideo[]) => void,
}

export const VideoPicker: React.FunctionComponent<VideoPickerProps> = (props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [canChooseFromCamera, setCanChooseFromCamera] = React.useState<boolean>(false);
    const [canChooseFromGallery, setCanChooseFromGallery] = React.useState<boolean>(false);
    const {label} = useI18n();

    React.useEffect(() => {
        videoHandling.canLoadVideosFromCamera().then(setCanChooseFromCamera);
        videoHandling.canLoadVideosFromGallery().then(setCanChooseFromGallery);
    }, []);

    const addVideos = (videos: TemporaryVideo[]) => props.onChange([...props.videos, ...videos]);


    const removeVideo = async (index: number): Promise<void> => {
        const videoToRemove = props.videos[index];
        const newVideos = props.videos.filter((_, i) => i !== index);
        props.onChange(newVideos);

        await fileHandling.deleteFile(videoToRemove.thumbnail);
        await fileHandling.deleteFile(videoToRemove);
    };

    const addVideoFromCamera = async () => {
        if (props.videos.length >= props.max) {
            return;
        }

        setLoading(true);
        try {
            addVideos(await videoHandling.loadVideosFromCamera(validateVideo));
        } catch (e: any) {
            console.error(e);
            setError(label('video_picker.error.generic'));
        } finally {
            setLoading(false);
        }
    };

    const addVideoFromGallery = async () => {
        if (props.videos.length >= props.max) {
            return;
        }

        setLoading(true);
        try {
            addVideos(await videoHandling.loadVideosFromGallery(validateVideo));
        } catch (e: any) {
            if (e instanceof ValidationError) {
                setError(e.userFacingMessage);
            } else {
                console.error(e);
                setError(label('video_picker.error.generic'));
            }
        } finally {
            setLoading(false);
        }
    };

    const validateVideo: ValidationFunction = (data): ValidationError | null => {
        if (data.sizeMb > props.maxSizeMb) {
            return new ValidationError(
                label('video_picker.error.file_too_large', {fileName: data.name, maxFileSizeMb: `${props.maxSizeMb}`}),
            );
        }

        if (data.lengthSeconds > 30) {
            return new ValidationError(
                label('video_picker.error.video_too_long', {
                    fileName: data.name,
                    maxFileSizeMb: `${props.maxSizeMb}`,
                    maxDurationSeconds: `${props.maxLengthS}`,
                }),
            );
        }

        return null;
    };

    const onFileSelected = async () => {
        setLoading(true);
        // for (let i = 0; i < e.target.files!.length; i++) {
        //   const file = e.target.files![i];
        //   const videoElement = await createVideoElement(file);
        //   const error = validateVideo(file, videoElement);
        //   if (error) {
        //     setError(error);
        //     setLoading(false);
        //     return;
        //   }
        //
        //   const [thumbnail, base64Data] = await Promise.all([
        //     getVideoThumbnail(videoElement),
        //     convertBlobToBase64(file),
        //   ]);
        //   addVideo({
        //     name: file.name,
        //     sizeBytes: file.size,
        //     sizeMb: Math.round(file.size / 1024 / 1024 * 100) / 100,
        //     thumbnail,
        //     base64Data,
        //   });
        // }
        setLoading(false);
    };

    return <React.Fragment>
        <IonText color="primary">
            <h5>{label('video_picker.title')}</h5>
        </IonText>

        {(props.videos.length >= props.max) ??
            <Alert severity="info">{label('video_picker.error.maximum_exceeded', {maxVideos: `${props.max}`})}</Alert>}
        <ErrorBox error={error}/>

        <IonItem>
            <IonLabel>
                {label('video_picker.select')}
                ({props.videos.length} / {props.max})
            </IonLabel>
            <IonButtons>

                {/* Add from camera button */}
                {canChooseFromCamera &&
                    <IonButton onClick={addVideoFromCamera} disabled={props.videos.length >= props.max}>
                        <IonIcon icon={videocam}/>
                    </IonButton>}

                {/* Add from gallery button */}
                {canChooseFromGallery &&
                    <IonButton onClick={addVideoFromGallery} disabled={props.videos.length >= props.max}>
                        <IonIcon icon={play}/>
                    </IonButton>}
            </IonButtons>
        </IonItem>

        {props.videos.map((video, index) => <IonItem key={index}>
            <IonLabel>{video.fileName} ({video.sizeMb} MB)</IonLabel>
            <IonButton fill="clear" onClick={() => removeVideo(index)}>
                {label('video_picker.action.delete')}
            </IonButton>
            <IonThumbnail slot="end">
                <TemporaryVideoPreview video={video}/>
            </IonThumbnail>
        </IonItem>)}

        {loading && <IonProgressBar type="indeterminate"/>}

        <input
            hidden
            style={{display: 'none'}}
            type="file"
            onChange={onFileSelected}
            accept="video/*"
            ref={inputRef}
        />
    </React.Fragment>;
};