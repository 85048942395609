import React from 'react';
import {PageWrapper} from '../components/PageWrapper';
import {fileHandling, FileOnDisk, temporaryFiles} from '../data/files';
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonToolbar} from '@ionic/react';
import {environment} from '../environment';
import {useLoginState} from '../data/Login';
import {useInProgressProConditionsReport} from '../data/ConditionsReportProEditing';
import {Modal} from '../components/modal/Modal';
import {close} from 'ionicons/icons';
import {useHistory} from 'react-router';

export const DebugPage: React.FunctionComponent = () => {
    const history = useHistory();
    const {user} = useLoginState();
    const {stepData} = useInProgressProConditionsReport();

    const [files, setFiles] = React.useState<FileOnDisk[]>([]);
    const [preview, setPreview] = React.useState<string | null>(null);

    const loadFiles = async () => {
        setFiles(await fileHandling.listFiles());
    };

    const openFile = async (file: FileOnDisk) => {
        const uri = await fileHandling.getPublicUrl(file);
        setPreview(uri);
    };

    const deleteFile = async (file: FileOnDisk) => {
        await fileHandling.deleteFile(file);
        await loadFiles();
    };

    React.useEffect(() => {
        loadFiles();
    }, []);

    return <PageWrapper title="Debug" showBackButton={true} onBack={() => history.push('/')}>
        <h2>Environment</h2>
        <pre><code>{JSON.stringify(environment, null, 4)}</code></pre>

        <h2>User</h2>
        <pre><code>{JSON.stringify(user, null, 4)}</code></pre>

        <h2>CR</h2>
        <pre><code>{JSON.stringify(stepData, null, 4)}</code></pre>

        <h2>Files</h2>
        <IonButton onClick={loadFiles}>Reload</IonButton>
        <IonButton onClick={() => temporaryFiles.clearStorage()}>Clear</IonButton>
        <IonList>
            {files.map(file => <IonItem key={file.nativePath}>
                <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                    <div style={{width: '100px'}}>{file.sizeMb} MB</div>
                    <div style={{flex: 1, overflow: 'scroll'}}>{file.nativePath}</div>
                    <IonButton onClick={() => openFile(file)}>Open</IonButton>
                    <IonButton color="danger" onClick={() => deleteFile(file)}>Delete</IonButton>
                </div>
            </IonItem>)}
        </IonList>
        <Modal open={preview !== null} onClose={() => setPreview(null)}>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="end" className="ion-padding-end">
                        <IonButton onClick={() => setPreview(null)}>
                            <IonIcon icon={close}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <iframe src={preview!} style={{width: '100%', height: '100%'}}/>
            </IonContent>
        </Modal>
    </PageWrapper>;
};