import React from 'react';
import {useStorage} from './Helpers';
import {newsRepository} from './repository/News';

export interface NewsItem {
    id: string,
    title: string,
    text: string,
    severity: 'warning' | 'info' | 'error',
    actions: {
        url: string,
        text: string,
    }[]
}

interface NewsStorage {
    checked: number,
    news: NewsItem[]
}

const NewsContext = React.createContext<NewsItem[]>([]);
export const NewsProvider: React.FunctionComponent<{ children: React.ReactNode }> = props => {
    const [storage] = useStorage();
    const [news, setNews] = React.useState<NewsItem[]>([]);

    const fetchNews = async () => {
        try {
            const newNews = (await newsRepository.get())
                .filter(newsItem => newsItem.id !== '_template');

            setNews(newNews);
            storage.set('news', {
                checked: Date.now(),
                news: newNews,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    React.useEffect(() => {
        storage.get('news').then((news: NewsStorage | null) => {
            if (!news) {
                fetchNews();
                return;
            }

            setNews(news.news);
            if (Date.now() - news.checked > 1000 * 60 * 60 * 4) {
                fetchNews();
            }
        });
    }, []);

    return <NewsContext.Provider value={news}>{props.children}</NewsContext.Provider>;
};

export function useNews(): NewsItem[] {
    return React.useContext(NewsContext);
}