import React from 'react';
import {Alert} from '@mui/material';
import {IonItem} from '@ionic/react';

interface ValidationWarningProps {
    errors: string[];
}

export const ValidationWarning: React.FunctionComponent<ValidationWarningProps> = (props) => {
    if (props.errors.length === 0) {
        return null;
    }

    return <Alert severity="warning">
        <ul>
            {props.errors.map(err => <li key={err}>{err}</li>)}
        </ul>
    </Alert>;
};


interface ValidationWarningsProps {
    errors: Record<string | number, string[] | undefined>;
}

export const ValidationWarnings: React.FunctionComponent<ValidationWarningsProps> = (props) => {
    const errors: string[] = [];
    for (const key in props.errors) {
        for (const error of props.errors[key] ?? []) {
            errors.push(error);
        }
    }

    if (errors.length === 0) {
        return null;
    }

    return <Alert severity="warning">
        <ul>
            {errors.map(err => <li key={err}>{err}</li>)}
        </ul>
    </Alert>;
};

export const IonItemWithValidation: React.FunctionComponent<{
    errors: string[] | undefined,
    children: React.ReactNode
}> = (props) => {
    const errors = props.errors ?? [];

    return <IonItem color={errors.length > 0 ? 'warning' : ''}>
        {props.children}
    </IonItem>;
};