import {implementationChooser} from '../Helpers';
import {WebTokenStorage} from './WebTokenStorage';
import {NativeTokenStorage} from './NativeTokenStorage';
import {environment} from '../../environment';
import {FixedTokenStorage} from './FixedTokenStorage';
import {Storage} from '@ionic/storage';

export interface TokenStorage {
    allowsModification(): Promise<boolean>;

    get(): Promise<string | null>;

    set(token: string): Promise<void>;

    remove(): Promise<void>;
}

// If available, then the key is stored in local storage under the following key. This is done in
// order to be able to reuse the token set by other parts of the website. (See also: statamic)
const LOCAL_STORAGE_KEY = 'powderguide-api-token';


// The ionic storage is used as a fallback. The key is left in place from previous versions.
const IONIC_STORAGE_KEY = 'token';

export function createNewTokenStorage(storage: Storage) {
    if (environment.apiToken !== null) {
        return new FixedTokenStorage(environment.apiToken);
    }

    return implementationChooser<TokenStorage>({
        web: () => new WebTokenStorage(LOCAL_STORAGE_KEY),
        ios: () => new NativeTokenStorage(storage, IONIC_STORAGE_KEY),
        android: () => new NativeTokenStorage(storage, IONIC_STORAGE_KEY),
    });
}
