import {implementationChooser} from "../Helpers";
import {WebGeolocationProvider} from "./WebGeolocationProvider";
import {NativeGeolocationProvider} from "./NativeGeolocationProvider";

export interface GeolocationInformation {
    latitude: number,
    longitude: number,
}

export interface GeolocationProvider {
    getGeolocation(): Promise<GeolocationInformation>;
    distanceBetween(a: GeolocationInformation, b: GeolocationInformation): number;
}

export const geolocationProvider = implementationChooser<GeolocationProvider>({
  ios: () => new NativeGeolocationProvider(),
  android: () => new NativeGeolocationProvider(),
  web: () => new WebGeolocationProvider(),
});