import {TokenStorage} from './index';
import {Storage} from '@ionic/storage';

export class NativeTokenStorage implements TokenStorage {
    constructor(
        private storage: Storage,
        private readonly storageKey: string,
    ) {
    }

    async allowsModification(): Promise<boolean> {
        return true;
    }

    get(): Promise<string | null> {
        return this.storage.get(this.storageKey);
    }

    set(token: string): Promise<void> {
        return this.storage.set(this.storageKey, token);
    }

    remove(): Promise<void> {

        return this.storage.remove(this.storageKey);
    }

}