import React from 'react';
import {
    IonAccordion,
    IonAccordionGroup,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonRange,
    IonSelect,
    IonSelectOption,
    IonTextarea,
} from '@ionic/react';
import {IonItemWithValidation} from '../../../components/ValidationWarning';
import {StarSelector} from '../../../components/StarSelector';
import {Exposition} from '../../../data/ConditionsReport';
import {ExpositionSelectorMultiple} from '../../../components/ExpositionSelector';
import {StepScope, StepValidationResult, ValidationScope} from '../../../data/ConditionsReportProEditing';
import {useI18n} from '../../../i18n/i18n';

export interface Step2Data {
    snowConditionRating: number | null,
    snowConditionDescription: string,
    snowConditionDescentToValley: boolean,
    snowConditionDescentToHeight: number,
    snowConditionDescentThroughForest: boolean,
    snowConditionBlownBacks: boolean,
    snowConditionCoveredShoes: boolean,
    snowConditionVariationsOfSnowCover: boolean,
    snowConditionStoneContactAvoidable: boolean,
    snowConditionFreshSnow: number | null,

    snowQualityRating: number | null,
    snowQualityDescription: string,
    snowQualityOldSnow: boolean,
    snowQualityBreakableCrust: boolean,
    snowQualitySlush: boolean,
    snowQualityFirn: boolean,
    snowQualityWetSnow: boolean,
    snowQualityPowder: boolean,
    snowQualitySummerSnow: boolean,
    snowQualityJellySnow: boolean,
    snowQualityWindDrifted: boolean,
    snowQualityWindDriftedExposition: Exposition[],
    snowQualityWindDriftedAltitude: { upper: number, lower: number } | null,
    snowQualityWindDriftedIntensity: number,
    snowQualityWindDriftedDescription: string,
}

export function Step2EmptyData(): Step2Data {
    return {
        snowConditionRating: null,
        snowConditionDescription: '',
        snowConditionDescentToValley: false,
        snowConditionDescentToHeight: 0,
        snowConditionDescentThroughForest: false,
        snowConditionBlownBacks: false,
        snowConditionCoveredShoes: false,
        snowConditionVariationsOfSnowCover: false,
        snowConditionStoneContactAvoidable: false,
        snowConditionFreshSnow: null,

        snowQualityRating: null,
        snowQualityDescription: '',
        snowQualityOldSnow: false,
        snowQualityBreakableCrust: false,
        snowQualitySlush: false,
        snowQualityFirn: false,
        snowQualityWetSnow: false,
        snowQualityPowder: false,
        snowQualitySummerSnow: false,
        snowQualityJellySnow: false,
        snowQualityWindDrifted: false,
        snowQualityWindDriftedExposition: [],
        snowQualityWindDriftedAltitude: null,
        snowQualityWindDriftedIntensity: 0,
        snowQualityWindDriftedDescription: '',
    };
}

export function validateStep2({data, label}: ValidationScope<Step2Data>): StepValidationResult<Step2Data> {
    const validation: StepValidationResult<Step2Data> = {};

    if (data.snowConditionFreshSnow === null) {
        validation.snowConditionFreshSnow = [label('cr.snow_condition.fresh_snow.validation.exists')];
    }

    if (data.snowConditionRating === null) {
        validation.snowConditionRating = [label('cr.snow_condition.rating.validation.exists')];
    }

    if (data.snowConditionDescentToValley && data.snowConditionDescentToHeight === 0) {
        validation.snowConditionDescentToHeight = [label('cr.snow_condition.descent_to_height.validation.exists')];
    }

    if (data.snowQualityRating === null) {
        validation.snowQualityRating = [label('cr.snow_quality.rating.validation.exists')];
    }

    if (data.snowQualityWindDrifted) {
        if (!data.snowQualityWindDriftedIntensity) {
            validation.snowQualityWindDriftedIntensity = [label('cr.snow_quality.wind_drifted_intensity.validation.exists')];
        }
        if (data.snowQualityWindDrifted && data.snowQualityWindDriftedExposition.length === 0) {
            validation.snowQualityWindDriftedExposition = [label('cr.snow_quality.wind_drifted_exposition.validation.exists')];
        }
    }

    return validation;
}

export const Step2Form: React.FunctionComponent<StepScope<Step2Data>> = (props) => {
    const {label} = useI18n();
    const {data, updateData, validation} = props;

    let snowQualityValid = true;
    let snowConditionValid = true;
    for (const [key, value] of Object.entries(validation)) {
        if (key.startsWith('snowQuality') && value.length > 0) {
            snowQualityValid = false;
        }
        if (key.startsWith('snowCondition') && value.length > 0) {
            snowConditionValid = false;
        }
    }

    return <React.Fragment>
        <IonAccordionGroup>
            <IonAccordion>
                <IonItem slot="header" color={snowConditionValid ? '' : 'warning'}>
                    <IonLabel>{label('cr.snow_condition')}</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">

                    <IonItemWithValidation errors={validation.snowConditionRating}>
                        <IonLabel slot="start">{label('cr.snow_condition.rating')}*</IonLabel>
                        <StarSelector
                            value={Number(data.snowConditionRating)}
                            onChange={stars => updateData({snowConditionRating: stars})}
                            max={5}
                        />
                    </IonItemWithValidation>

                    <IonItemWithValidation errors={validation.snowConditionDescription}>
                        <IonTextarea
                            placeholder={label('cr.snow_condition.description')}
                            value={data.snowConditionDescription}
                            onIonInput={e => updateData({snowConditionDescription: e.detail.value!})}
                        />
                    </IonItemWithValidation>

                    <IonItemWithValidation errors={validation.snowConditionDescentToValley}>
                        <IonCheckbox checked={data.snowConditionDescentToValley}
                                     onIonChange={e => updateData({snowConditionDescentToValley: e.detail.checked})}>
                            {label('cr.snow_condition.descent_to_valley')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    {data.snowConditionDescentToValley ?
                        <IonItemWithValidation errors={validation.snowConditionDescentToHeight}>
                            <IonRange
                                value={data.snowConditionDescentToHeight}
                                onIonChange={(e: any) => updateData({snowConditionDescentToHeight: e.detail.value})}
                                min={0}
                                max={4800}
                                step={100}
                                pin={true}
                                labelPlacement="start"
                            >
                                <IonLabel slot="label">
                                    {label('cr.snow_condition.descent_to_height', {descentToHeight: `${data.snowConditionDescentToHeight}`})}
                                </IonLabel>
                            </IonRange>
                        </IonItemWithValidation> : null}

                    <IonItemWithValidation errors={validation.snowConditionDescentThroughForest}>
                        <IonCheckbox checked={data.snowConditionDescentThroughForest}
                                     onIonChange={e => updateData({snowConditionDescentThroughForest: e.detail.checked})}>
                            {label('cr.snow_condition.descent_through_forest')}
                        </IonCheckbox>
                    </IonItemWithValidation>

                    <IonItemWithValidation errors={validation.snowConditionBlownBacks}>
                        <IonCheckbox checked={data.snowConditionBlownBacks}
                                     onIonChange={e => updateData({snowConditionBlownBacks: e.detail.checked})}>
                            {label('cr.snow_condition.blown_backs')}
                        </IonCheckbox>
                    </IonItemWithValidation>

                    <IonItemWithValidation errors={validation.snowConditionCoveredShoes}>
                        <IonCheckbox checked={data.snowConditionCoveredShoes}
                                     onIonChange={e => updateData({snowConditionCoveredShoes: e.detail.checked})}>
                            {label('cr.snow_condition.covered_shoes')}
                        </IonCheckbox>
                    </IonItemWithValidation>

                    <IonItemWithValidation errors={validation.snowConditionVariationsOfSnowCover}>
                        <IonCheckbox checked={data.snowConditionVariationsOfSnowCover}
                                     onIonChange={e => updateData({snowConditionVariationsOfSnowCover: e.detail.checked})}>
                            {label('cr.snow_condition.variations_of_snow_cover')}
                        </IonCheckbox>
                    </IonItemWithValidation>

                    <IonItemWithValidation errors={validation.snowConditionStoneContactAvoidable}>
                        <IonCheckbox checked={data.snowConditionStoneContactAvoidable}
                                     onIonChange={e => updateData({snowConditionStoneContactAvoidable: e.detail.checked})}>
                            {label('cr.snow_condition.stone_contact_avoidable')}
                        </IonCheckbox>
                    </IonItemWithValidation>

                    <IonItemWithValidation errors={validation.snowConditionFreshSnow}>

                        <IonRange
                            value={Number(data.snowConditionFreshSnow)}
                            onIonChange={(e: any) => updateData({snowConditionFreshSnow: e.detail.value})}
                            min={0}
                            max={105}
                            step={5}
                            pin={true}
                            labelPlacement="start"
                        >
                            <IonLabel slot="label">
                                {label('cr.snow_condition.fresh_snow')}* <br/>
                                {Number(data.snowConditionFreshSnow) > 100 ? '> 100' : data.snowConditionFreshSnow} cm
                            </IonLabel>
                        </IonRange>
                    </IonItemWithValidation>

                </div>
            </IonAccordion>
            <IonAccordion>
                <IonItem slot="header" color={snowQualityValid ? '' : 'warning'}>
                    <IonLabel>{label('cr.snow_quality')}</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">

                    <IonItemWithValidation errors={validation.snowQualityRating}>
                        <IonLabel slot="start">{label('cr.snow_quality.rating')}*</IonLabel>
                        <StarSelector
                            value={Number(data.snowQualityRating)}
                            onChange={stars => updateData({snowQualityRating: stars})}
                            max={5}
                        />
                    </IonItemWithValidation>

                    <IonItemWithValidation errors={validation.snowQualityDescription}>
                        <IonTextarea
                            placeholder={label('cr.snow_quality.description')}
                            value={data.snowQualityDescription}
                            onIonInput={e => updateData({snowQualityDescription: e.detail.value!})}
                        />
                    </IonItemWithValidation>


                    <IonItemWithValidation errors={validation.snowQualityOldSnow}>
                        <IonCheckbox checked={data.snowQualityOldSnow}
                                     onIonChange={e => updateData({snowQualityOldSnow: e.detail.checked})}>
                            {label('cr.snow_quality.old_snow')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    <IonItemWithValidation errors={validation.snowQualityBreakableCrust}>
                        <IonCheckbox checked={data.snowQualityBreakableCrust}
                                     onIonChange={e => updateData({snowQualityBreakableCrust: e.detail.checked})}>
                            {label('cr.snow_quality.breakable_crust')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    <IonItemWithValidation errors={validation.snowQualitySlush}>
                        <IonCheckbox checked={data.snowQualitySlush}
                                     onIonChange={e => updateData({snowQualitySlush: e.detail.checked})}>
                            {label('cr.snow_quality.slush')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    <IonItemWithValidation errors={validation.snowQualityFirn}>
                        <IonCheckbox checked={data.snowQualityFirn}
                                     onIonChange={e => updateData({snowQualityFirn: e.detail.checked})}>
                            {label('cr.snow_quality.firn')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    <IonItemWithValidation errors={validation.snowQualityWetSnow}>
                        <IonCheckbox checked={data.snowQualityWetSnow}
                                     onIonChange={e => updateData({snowQualityWetSnow: e.detail.checked})}>
                            {label('cr.snow_quality.wet_snow')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    <IonItemWithValidation errors={validation.snowQualityPowder}>
                        <IonCheckbox checked={data.snowQualityPowder}
                                     onIonChange={e => updateData({snowQualityPowder: e.detail.checked})}>
                            {label('cr.snow_quality.powder')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    <IonItemWithValidation errors={validation.snowQualitySummerSnow}>
                        <IonCheckbox checked={data.snowQualitySummerSnow}
                                     onIonChange={e => updateData({snowQualitySummerSnow: e.detail.checked})}>
                            {label('cr.snow_quality.summer_snow')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    <IonItemWithValidation errors={props.validation.snowQualityJellySnow}>
                        <IonCheckbox checked={props.data.snowQualityJellySnow}
                                     onIonChange={e => updateData({snowQualityJellySnow: e.detail.checked})}>
                            {label('cr.snow_quality.jelly_snow')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    <IonItemWithValidation errors={validation.snowQualityWindDrifted}>
                        <IonCheckbox checked={data.snowQualityWindDrifted}
                                     onIonChange={e => updateData({snowQualityWindDrifted: e.detail.checked})}>
                            {label('cr.snow_quality.wind_drifted')}
                        </IonCheckbox>
                    </IonItemWithValidation>
                    {data.snowQualityWindDrifted ?
                        <div className="ion-padding-start"><WindDriftedSnowAttributesEditor data={data}
                                                                                            validation={validation}
                                                                                            onChange={updateData}/>
                        </div> : null}
                </div>
            </IonAccordion>
        </IonAccordionGroup>
    </React.Fragment>;
};

interface SnowQualityAttributesEditorProps {
    data: Step2Data,
    validation: StepValidationResult<Step2Data>
    onChange: (data: Partial<Step2Data>) => void,
}

const WindDriftedSnowAttributesEditor: React.FunctionComponent<SnowQualityAttributesEditorProps> = (props) => {
    const {label} = useI18n();
    const [allHeights, setAllHeights] = React.useState(false);

    return <React.Fragment>
        <IonItem>
            <IonSelect
                value={allHeights}
                onIonChange={e => setAllHeights(e.detail.value)}
                interface="action-sheet"
            >
                <IonSelectOption value={false}>{label('cr.altitude.select')}</IonSelectOption>
                <IonSelectOption value={true}>{label('cr.altitude.all')}</IonSelectOption>
            </IonSelect>
        </IonItem>
        {allHeights ? null : <IonItemWithValidation errors={props.validation.snowQualityWindDriftedAltitude}>
            <IonRange
                value={props.data.snowQualityWindDriftedAltitude ?? {lower: 0, upper: 4800}}
                onIonChange={(e: any) => props.onChange({snowQualityWindDriftedAltitude: e.detail.value})}
                dualKnobs={true}
                min={0}
                max={4800}
                step={100}
                pin={true}
            >
                <IonLabel slot="label">
                    {label('cr.altitude')}* <br/>
                    {props.data.snowQualityWindDriftedAltitude?.lower}m <br/>
                    {props.data.snowQualityWindDriftedAltitude?.upper}m
                </IonLabel>
            </IonRange>
        </IonItemWithValidation>}
        <IonItemWithValidation errors={props.validation.snowQualityWindDriftedIntensity}>
            <IonSelect
                value={props.data.snowQualityWindDriftedIntensity}
                onIonChange={e => props.onChange({snowQualityWindDriftedIntensity: e.detail.value})}
                label={label('cr.snow_quality.wind_drifted_intensity')}
                interface="action-sheet"
            >
                <IonSelectOption value={1}>{label('cr.snow_quality.wind_drifted_intensity.low')}</IonSelectOption>
                <IonSelectOption value={2}>{label('cr.snow_quality.wind_drifted_intensity.medium')}</IonSelectOption>
                <IonSelectOption value={3}>{label('cr.snow_quality.wind_drifted_intensity.high')}</IonSelectOption>
            </IonSelect>
        </IonItemWithValidation>
        <IonItemWithValidation errors={props.validation.snowQualityWindDriftedExposition}>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{label('cr.snow_quality.wind_drifted_exposition')}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <ExpositionSelectorMultiple
                        expositions={props.data.snowQualityWindDriftedExposition}
                        onChange={exposition => props.onChange({snowQualityWindDriftedExposition: exposition})}
                    />
                </IonCardContent>
            </IonCard>

        </IonItemWithValidation>
    </React.Fragment>;
};