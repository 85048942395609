import {cacheStorage} from './CacheStorage';
import {apiClient} from '../api';

export interface ShortSpot {
    uuid: string,
    title: string,
    country: string,
    location: {
        lat: number,
        long: number,
    },
}

export interface SpotCreation {
    title: string,
    location: {
        latitude: number,
        longitude: number,
    },
}

class SpotRepository {
    private spots: ShortSpot[] | null = null;

    async list(onAvailable?: (spots: ShortSpot[]) => void): Promise<ShortSpot[]> {
        if (this.spots) {
            if (onAvailable) {
                onAvailable(this.spots);
            }
            return this.spots;
        }

        const cached = cacheStorage.get<ShortSpot[]>('spot_list_2');
        if (cached && onAvailable) {
            onAvailable(cached);
        }

        const response = await apiClient.request({
            endpoint: 'api/v2/spot',
            method: 'GET',
        });
        const spots = await response.json() as ShortSpot[];

        cacheStorage.set('spot_list', spots);
        if (onAvailable) {
            onAvailable(spots);
        }

        return spots;
    }

    async create(token: string, spot: SpotCreation): Promise<ShortSpot> {
        const response = await apiClient.request({
            endpoint: 'api/v2/spot',
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: {
                title: spot.title,
                latitude: spot.location.latitude,
                longitude: spot.location.longitude,
            },
        });

        this.spots = null;
        return response.json() as Promise<ShortSpot>;
    }
}

export const spotRepository = new SpotRepository();