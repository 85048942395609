enum TaskStatus {
    Waiting = 'waiting',
    InProgress = 'inProgress',
    Done = 'done',
}

export class Task<T = any> {
    private status: TaskStatus = TaskStatus.Waiting;
    private collection: ObservableTaskCollection | null = null;

    constructor(
        public readonly name: string,
        private readonly callback: () => Promise<T>,
    ) {
    }

    public setCollection(collection: ObservableTaskCollection | null): void {
        this.collection = collection;
    }

    public isWaiting(): boolean {
        return this.status === TaskStatus.Waiting;
    }

    public isInProgress(): boolean {
        return this.status === TaskStatus.InProgress;
    }

    public isDone(): boolean {
        return this.status === TaskStatus.Done;
    }

    public start(): Promise<T> {
        this.status = TaskStatus.InProgress;
        this.collection?.informStatusChange();
        return this.callback().then((result) => {
            this.status = TaskStatus.Done;
            this.collection?.informStatusChange();
            return result;
        });
    }
}

export function runTasksInParallel<T>(tasks: Task<T>[]): Promise<T[]> {
    const promises: Promise<T>[] = [ ];
    for (const task of tasks) {
        promises.push(task.start());
    }
    return Promise.all(promises);
}

export async function runTasksInSerial<T>(tasks: Task<T>[]): Promise<T[]> {
    const results: T[] = [ ];
    for (const task of tasks) {
        results.push(await task.start());
    }
    return results;
}

export class ObservableTaskCollection<T = any> {
    private statusChangeCallbacks: Record<string, () => void> = {};

    constructor(
        private tasks: Task<T>[],
    ) {
        tasks.forEach(task => task.setCollection(this));
    }

    public informStatusChange(): void {
        Object.values(this.statusChangeCallbacks).forEach((callback) => callback());
    }

    public onStatusChange(callback: () => void): string {
        const id = Math.random().toString(26).substring(2);
        this.statusChangeCallbacks[id] = callback;

        return id;
    }

    public offStatusChange(id: string): void {
        delete this.statusChangeCallbacks[id];
    }

    public getTasks(): Task<T>[] {
        return this.tasks;
    }

    public getDoneTasks(): Task<T>[] {
        return this.tasks.filter((t) => t.isDone());
    }

    public getInProgressTasks(): Task<T>[] {
        return this.tasks.filter((t) => t.isInProgress());
    }

    public getWaitingTasks(): Task<T>[] {
        return this.tasks.filter((t) => t.isWaiting());
    }

    public addTask(task: Task<T>): void {
        task.setCollection(this);
        this.tasks.push(task);
        this.informStatusChange();
    }

    public removeTask(task: Task<T>): void {
        this.tasks = this.tasks.filter((t) => t !== task);
        task.setCollection(null);
        this.informStatusChange();
    }
}