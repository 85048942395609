import {useInProgressMiniConditionsReport} from "../../../data/ConditionsReportMiniEditing";
import {useHistory, useParams} from "react-router";
import {ID_NEW} from "../../../data/ConditionsReportProEditing";
import React from "react";
import {conditionsReportRepository} from "../../../data/repository/ConditionsReport";
import {PageWrapper} from "../../../components/PageWrapper";
import {useI18n} from "../../../i18n/i18n";
import {useLoginState} from "../../../data/Login";
import {Redirect} from "react-router-dom";
import {IonButton} from "@ionic/react";
import {ConditionsReportMiniForm} from "./Form";
import {ValidationWarnings} from "../../../components/ValidationWarning";

export const ConditionsReportMiniEditingPage: React.FunctionComponent = () => {
  const params = useParams() as { id: string };
  const {label} = useI18n();
  const {user} = useLoginState();
  const history = useHistory();

  const {
    id,
    initialize,
    goToPreview,
    formData,
    updateFormData,
    validation,
    showValidation,
  } = useInProgressMiniConditionsReport();

  React.useEffect(() => {
    if (params.id === id) {
      return;
    }

    if (params.id === ID_NEW) {
      initialize(null);
    }

    conditionsReportRepository.load(params.id).then(initialize);
  });

  if (user === null) {
    return <Redirect to="/home"/>;
  }

  return <PageWrapper
    title={label('editing.title')}
    showBackButton={true}
    onBack={() => history.push('/home')}
    footer={
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div />
        <IonButton onClick={goToPreview}>{label('editing.action.send')}</IonButton>
      </div>
    }
  >
    {showValidation && <ValidationWarnings errors={validation ?? {}}/>}

    <ConditionsReportMiniForm
      formData={formData}
      updateFormData={updateFormData}
      validation={showValidation ? validation : { } }
    />
  </PageWrapper>
}
