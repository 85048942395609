import {Capacitor} from '@capacitor/core';
import {FileOnDisk} from './index';
import {GenericFileHandlingBackend} from './GenericFileHandlingBackend';

export class NativeFileHandlingBackend extends GenericFileHandlingBackend {

    // Reading a blob through fetch is faster and more reliable than using
    // the filesystem API.
    async readFile(file: FileOnDisk): Promise<Blob> {
        const result = await fetch(await this.getPublicUrl(file));

        return await result.blob();
    }

    async getPublicUrl(file: FileOnDisk): Promise<string> {
        return Capacitor.convertFileSrc(file.nativePath);
    }
}