import {ConditionsReport} from '../ConditionsReport';
import {apiClient} from '../api';

export type ConditionsReportWithId = ConditionsReport & {uuid: string, url?: string};

class ConditionsReportRepository {
    async submit(cr: ConditionsReport, token: string): Promise<ConditionsReportWithId> {
        const response = await apiClient.request({
            endpoint: 'api/v2/conditions_report',
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: cr,
        });

        return await response.json() as ConditionsReportWithId;
    }

    async load(id: string): Promise<ConditionsReportWithId> {
        const response = await apiClient.request({
            endpoint: `api/v2/conditions_report/${id}`,
            method: 'GET',
        });

        return await response.json() as ConditionsReportWithId;
    }
}

export const conditionsReportRepository = new ConditionsReportRepository();