import React, {FunctionComponent} from 'react';
import {Exposition} from '../data/ConditionsReport';

interface ExpositionWindRoseProps {
    active: Exposition[],
    size?: number,
    onClick?: (exposition: Exposition, isCurrentlyActive: boolean) => void,
    showText?: boolean
}

export const ExpositionWindRose: FunctionComponent<ExpositionWindRoseProps> = (props) => {
    const size = props.size ?? 200;
    const showText = props.showText ?? true;

    const directions: Exposition[] = [
        Exposition.North,
        Exposition.NorthEast,
        Exposition.East,
        Exposition.SouthEast,
        Exposition.South,
        Exposition.SouthWest,
        Exposition.West,
        Exposition.NorthWest,
    ];

    const active = directions.filter(direction => props.active.includes(direction));
    const inactive = directions.filter(direction => !props.active.includes(direction));
    const onClick = (exposition: Exposition) => {
        if (props.onClick) {
            props.onClick(exposition, props.active.includes(exposition));
        }
    };

    // In order to ensure correct z-index layering, the inactive blades are drawn first,
    // then the active ones and then a layer containing only outlines of the blades.
    return <svg viewBox="0 0 100 100" style={{
        position: 'relative',
        width: `${size}px`,
        height: `${size}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>

        {inactive.map(direction => <WindRoseBlade
            key={direction}
            exposition={direction}
            strokeColor="transparent"
            fillColor="#e5e5e5"
            fillOpacity={1}
            onClick={() => onClick(direction)}
            showLabel={showText}
            labelColor="#777"
        />)}

        {active.map(direction => <WindRoseBlade
            key={direction}
            exposition={direction}
            strokeColor="transparent"
            fillColor="#3880ff"
            fillOpacity={1}
            onClick={() => onClick(direction)}
            showLabel={showText}
            labelColor="#3880ff"
        />)}

        {directions.map(direction => <WindRoseBlade
            key={direction}
            exposition={direction}
            strokeColor="#666"
            fillColor="transparent"
            fillOpacity={0}
            onClick={() => onClick(direction)}
            showLabel={false}
            labelColor="transparent"
        />)}
    </svg>;
};

const Rotations: Record<Exposition, number> = {
    [Exposition.North]: 0,
    [Exposition.NorthEast]: 45,
    [Exposition.East]: 90,
    [Exposition.SouthEast]: 135,
    [Exposition.South]: 180,
    [Exposition.SouthWest]: 225,
    [Exposition.West]: 270,
    [Exposition.NorthWest]: 315,
};

const TextPositions: Record<Exposition, { x: number, y: number, anchor: 'middle' | 'end' | 'start' }> = {
    [Exposition.North]: {x: 50, y: 6, anchor: 'middle'},
    [Exposition.NorthEast]: {x: 98, y: 6, anchor: 'end'},
    [Exposition.East]: {x: 98, y: 52.5, anchor: 'end'},
    [Exposition.SouthEast]: {x: 98, y: 98, anchor: 'end'},
    [Exposition.South]: {x: 50, y: 98, anchor: 'middle'},
    [Exposition.SouthWest]: {x: 2, y: 98, anchor: 'start'},
    [Exposition.West]: {x: 2, y: 52.5, anchor: 'start'},
    [Exposition.NorthWest]: {x: 2, y: 6, anchor: 'start'},
};

const ExpositionNames: Record<Exposition, string> = {
    [Exposition.North]: 'N',
    [Exposition.NorthEast]: 'NE',
    [Exposition.East]: 'E',
    [Exposition.SouthEast]: 'SE',
    [Exposition.South]: 'S',
    [Exposition.SouthWest]: 'SW',
    [Exposition.West]: 'W',
    [Exposition.NorthWest]: 'NW',
};

interface WindRoseBladeProps {
    exposition: Exposition,
    fillColor: string,
    fillOpacity: number,
    strokeColor: string,
    onClick: () => void,
    showLabel: boolean,
    labelColor: string,
}

const WindRoseBlade: FunctionComponent<WindRoseBladeProps> = (props) => {
    const position = TextPositions[props.exposition];

    return <g>
        <path
            onClick={() => props.onClick()}
            style={{
                pointerEvents: 'all',
                fill: props.fillColor,
                fillOpacity: props.fillOpacity,
                stroke: props.strokeColor,
                strokeWidth: 0.264583,
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeOpacity: 1,
                transform: `rotate(${Rotations[props.exposition]}deg)`,
                transformOrigin: '50% 50%',
            }}
            d="M 50,50 41.25,41.25 50,8.75 58.75,41.25 Z"
            id="path454"/>
        {props.showLabel ? <text x={position.x} y={position.y} textAnchor={position.anchor} fontSize={7}
                                 fill={props.labelColor}>{ExpositionNames[props.exposition]}</text> : null}
    </g>;
};
