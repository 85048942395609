import {useEffect, useState} from 'react';
import {Storage} from '@ionic/storage';
import {isPlatform} from '@ionic/react';

export function useStorage(): [Storage] {
    const [storage] = useState(new Storage());

    useEffect(() => {
        storage.create();
    }, []);

    return [storage];
}

export function useOnlineState(): [boolean] {
    const [online, setOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handler = () => setOnline(navigator.onLine);
        window.addEventListener('online', handler);
        window.addEventListener('offline', handler);
        return () => {
            window.removeEventListener('online', handler);
            window.removeEventListener('offline', handler);
        };
    }, []);

    return [online];
}

export function trim(input: string, chars: string): string {
    return input.replace(new RegExp(`^[${chars}]+|[${chars}]+$`, 'g'), '');
}

export function trimEnd(input: string, chars: string): string {
    return input.replace(new RegExp(`[${chars}]+$`, 'g'), '');
}

export function trimStart(input: string, chars: string): string {
    return input.replace(new RegExp(`^[${chars}]+`, 'g'), '');
}

export function implementationChooser<T>(implementations: { ios: () => T, android: () => T, web: () => T }): T {
    if (isPlatform('hybrid') && isPlatform('ios')) {
        return implementations.ios();
    }
    if (isPlatform('hybrid') && isPlatform('android')) {
        return implementations.android();
    }

    return implementations.web();
}