import {GeolocationInformation, GeolocationProvider} from "./index";
import { Geolocation } from "@capacitor/geolocation";
import { getDistance } from 'geolib';

export class NativeGeolocationProvider implements GeolocationProvider {
  async getGeolocation(): Promise<GeolocationInformation> {
    await Geolocation.requestPermissions();

    const position = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
    });

    return {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
  }

  distanceBetween(a: GeolocationInformation, b: GeolocationInformation): number {
    return getDistance(a, b);
  }
}