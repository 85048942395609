import {Camera} from '@capacitor/camera';
import {TemporaryImage} from '.';
import {GenericImageHandlingBackend} from './GenericImageHandlingBackend';

export class NativeImageHandlingBackend extends GenericImageHandlingBackend {
    async loadPhotoFromCamera(maxSize: { width: number; height: number; }): Promise<TemporaryImage | null> {
        await Camera.requestPermissions({permissions: ['camera']}).catch(e => console.warn(e));
        return super.loadPhotoFromCamera(maxSize);
    }

    async loadPhotosFromGallery(maxSize: { width: number; height: number; }): Promise<TemporaryImage[]> {
        await Camera.requestPermissions({permissions: ['photos']});
        return super.loadPhotosFromGallery(maxSize);
    }
}