import React from 'react';
import Fuse from 'fuse.js';

export function useSearchableCollection<T>(searchFieldCollector: (item: T) => string[]) {
    const [all, setAll] = React.useState<T[]>([]);
    const [results, setResults] = React.useState<T[]>([]);
    const [query, setQuery] = React.useState<string>('');
    const [empty, setEmpty] = React.useState<boolean>(true);

    const fuse = new Fuse(all, {
        // The key is just a placeholder, the real fields are collected by the searchFieldCollector
        keys: ['.'],
        getFn: (item: T) => searchFieldCollector(item),

        findAllMatches: true,
    });

    function update(): void {
        fuse.setCollection(all);

        if (query === '') {
            setResults([...all]);
            setEmpty(false);
        } else {
            const results = fuse.search(query);
            setResults(results.map(({item}) => item));
            setEmpty(results.length === 0);
        }
    }

    React.useEffect(update, [all, query]);

    return {
        results,
        all,
        query,
        empty,
        update: setAll,
        search: (query: string): void => setQuery(query.trim()),
    };
}